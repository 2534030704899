import React, { useState, useRef, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import LoadingAnimation from './LoadingAnimation';
import sendSVG from './sendSVG.svg';
import msol from './msol.jpeg';
import {dias, meses, anios} from "./api/datesApi"
import { fetchDataFromBackend, postDataToBackend } from './api/forwardToBackend';

function App() {
  const [startTime] = useState(Date.now());
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [conversationId, setConversationId] = useState(''); 
  const [conversationIdSimple, setConversationIdSimple] = useState(0); 
  const [agentName, setAgentName] = useState('Marisol'); 
  const [agentTitle, setAgentTitle] = useState('Agente Especializado'); 
  const [firstMessage, setFirstMessage] = useState('¡Hola! Cuéntame, ¿para que auto deseas cotizar un seguro?'); 
  const [loaded1, setLoaded1] = useState(false);
  const [loaded2, setLoaded2] = useState(false);
  const [isTypingVisible, setIsTypingVisible] = useState(false);
  const [parentPage, setParentPage] = useState("");
  const [parentPageURL, setParentPageURL] = useState("");
  const [gclid, setGclid] = useState("");
  const [loadTime, setLoadTime] = useState(0);
  const [isFocusLogged, setIsFocusLogged] = useState(false);
  const [marca, setMarca] = useState("");
  const [anio, setAnio] = useState("");
  const [submarca, setSubmarca] = useState("");
  const [genero, setGenero] = useState(null);
  const [renderGenero, setRenderGenero] = useState(false);
  const [tipo, setTipo] = useState(null);
  const [renderTipo, setRenderTipo] = useState(false);
  const [dia, setDia] = useState("");
  const [mes, setMes] = useState("");
  const [anioNac, setAnioNac] = useState("");
  const [renderFecha, setRenderFecha] = useState(false);
  const [shouldShowIndicator, setShouldShowIndicator] = useState(false);
  const [carData, setCarData] = useState({});
  const [collectedData, setCollectedData] = useState({})
  const bottomRef = useRef(null);
  const scrollContainerRef = useRef(null); // New ref for the scroll container

  const firstMessageRef = useRef(firstMessage);

  useEffect(() => {
    const fetchCarData = async () => {
      try {
        const response = await fetchDataFromBackend('/getCars');
        setCarData(response);
      } catch (error) {
        console.error('Error fetching car data:', error);
        setCarData({}); // Set empty object in case of error
      }
    };

    fetchCarData();
  }, []);

  useEffect(() => {
    firstMessageRef.current = firstMessage;
  }, [firstMessage]);

  useEffect(() => {
    const handleMessage = (event) => {
      const allowedOrigins = ["https://seguros-ert.mx", "https://kayum.mx"];
      if (allowedOrigins.includes(event.origin)) {
        console.log(`Message received from allowed origin ${event.data.origin}:`, event);
        console.log(`Message full url ${event.data.fullUrl}:`, event);
        setParentPage(event.data.origin);
        setParentPageURL(event.data.fullUrl);
        const url = new URL(event.data.fullUrl);
        const gclidValue = url.searchParams.get("gclid");
        if (gclidValue) {
          setGclid(gclidValue);
        }
      }
    };
  
    window.addEventListener("message", handleMessage);
  
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    const fetchData = async (loadDuration) => {
      // Determine if the device is mobile or desktop
      const isMobile = /Mobi|Android|iPhone|iPad|iPod|Windows Phone|BlackBerry|webOS/i.test(navigator.userAgent);
  
      const payload = {
        loadTime: loadDuration,
        convId: conversationId,
        origin: parentPage,
        url: parentPageURL,
        deviceType: isMobile ? "mobile" : "desktop" // Add the device type
      };
      try {
        await postDataToBackend('/time', payload);
      } catch (error) {
        console.error('Error calling API:', error);
      }
    };
  
    if (loadTime !== 0 && parentPage !== "" && parentPageURL !== "") {
      fetchData(loadTime);
    }
  }, [conversationId, parentPage, parentPageURL, loadTime]);

  // Effect to monitor changes to messages
  useEffect(() => {
    const targeWordsTipo = ["particular", "uber", "plataforma", "uso le das"];
    const targeWordsGenero = ["masculino", "femenino"];
    const targetWordsEdad = ["cuántos años tienes", "cuál es tu edad", "me puedes decir tu edad", "puedes decirme tu edad"]

    if (messages.length > 0) {
      const lastMessageContent = messages[messages.length - 1]?.content || ""; // Safely access content

      const containsAllTipoWords = targeWordsTipo.every(word =>
        lastMessageContent.toLowerCase().includes(word.toLowerCase())
      );

      const containsAllGenderWords = targeWordsGenero.every(word =>
        lastMessageContent.toLowerCase().includes(word.toLowerCase())
      );

      const containsAnyAgeWords = targetWordsEdad.some(word =>
        lastMessageContent.toLowerCase().includes(word.toLowerCase())
      );

      setRenderTipo(containsAllTipoWords);
      setRenderGenero(containsAllGenderWords);
      setRenderFecha(containsAnyAgeWords)
    }
  }, [messages]);

  useEffect(() => {
    if (loaded1 && loaded2) {
      const loadDuration = Date.now() - startTime;
      setLoadTime(loadDuration)
    }
  }, [loaded1, loaded2, startTime]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Adjust the endpoint path as needed for your config endpoint
        const result = await fetchDataFromBackend('/getConfig');
        setAgentName(result.agentName);
        setFirstMessage(result.firstMessage);
        setAgentTitle(result.agentTitle)
        //Aqui falta el Agente Especializado
      } catch (error) {
        console.error('Failed to fetch data:', error);
      } finally {
        setLoaded1(true);
      }
    };
  
    fetchData();
  }, []);

  const reloadChat = async (id) => {
    setIsTypingVisible(false);
    const payload = { convId: id };
  
    try {
      // Make the POST request using postDataToBackend
      const data = await postDataToBackend('/getChat', payload);
  
      if (data.status === 'success') {
        setMessages(
          data.messages.length > 2 ? data.messages : [{ role: 'assistant', content: firstMessageRef.current }]
        );
        setConversationIdSimple(data.conv_id_simple ? data.conv_id_simple : 0) 
      } else {
        console.error('API response error:', data);
      }
    } catch (error) {
      console.error('Error calling API:', error);
      setMessages(
        [{ role: 'assistant', content: firstMessageRef.current }]
      );
    }
  };

  useEffect(() => {
    const existingId = localStorage.getItem('conversationId');
    if (existingId) {
      setConversationId(existingId);
      reloadChat(existingId);
    } else {
      const newId = uuidv4();
      localStorage.setItem('conversationId', newId);
      setConversationId(newId);
      reloadChat(newId);
    }
    setLoaded2(true);
  }, [setLoaded2]);

  const handleSendMessage = async (value) => {  
    // Ensure 'value' is a string, fallback to ''
    const safeValue = typeof value === 'string' ? value : '';
  
    const trimmedInput = input.trim();
  
    if (trimmedInput === '/clear') {
      const newId = uuidv4();
      localStorage.setItem('conversationId', newId);
      setConversationId(newId);
      reloadChat(newId);
      setInput('');
      return;
    }
  
    if (trimmedInput === '/internal' || trimmedInput === '/int') {
      const payload = { tag: "internal", convId: conversationId };
      await postDataToBackend('/tag', payload);
      setInput('');
      return;
    }
  
    if (trimmedInput !== '' && input.length <= 300 && messages.length <= 110) {
      const newMessages = [...messages, { content: input, role: 'user' }];
      setMessages(newMessages);
      setInput('');
      setTimeout(() => setIsTypingVisible(true), 500);
      await callApi(newMessages);
      setIsTypingVisible(false);
      return;
    }
  
    if (safeValue) {
      const newMessages = [...messages, { content: safeValue, role: 'user' }];
      setMessages(newMessages);
      setInput('');
      setTimeout(() => setIsTypingVisible(true), 500);
      await callApi(newMessages);
      setIsTypingVisible(false);
    }
  };

  const handleMarcaChange = (e) => {
    setMarca(e.target.value);
    setAnio("");
    setSubmarca("");
    setInput("");
  };

  const handleAnioChange = (e) => {
    setAnio(e.target.value);
    setSubmarca("");
    setInput("");
  };

  const handleSubmarcaChange = (e) => {
    setSubmarca(e.target.value);
    setInput('Un ' + marca + ' ' + e.target.value + ' ' + anio)
  };

  const handleGeneroSelected = (value) => {
    setGenero(value)
    //setInput(value)
    handleSendMessage(value);
  }

  const handleTipoSelected = (value) => {
    setTipo(value)
    //setInput(value)
    handleSendMessage(value);
  }

  function isDateValid(yy,mm,dd) {
    return !isNaN(new Date(yy,mm,dd));
  }

  const handleDiaChange = (e) => {
    setDia(e.target.value)
    setMes("")
    setAnioNac("")
  }

  const handleMesChange = (e) => {
    setMes(e.target.value)
    setAnioNac("")
  }

  const handleAnioNacChange = (e) => {
    setAnioNac(e.target.value)
    const numMes = parseInt(meses.indexOf(mes))
    const dateValid = isDateValid(e.target.value, numMes, dia)
    console.log(dateValid)
    if(dateValid){
      const nacimiento = new Date(e.target.value, numMes, dia)
      const diff = Date.now() - nacimiento
      const ageDate = new Date(diff);
      const age = Math.abs(ageDate.getUTCFullYear() - 1970)
      //setInput(`Soy del ${dia} de ${mes} de ${e.target.value}. Tengo ${age} años.`)
      handleSendMessage(`Soy del ${dia} de ${mes} de ${e.target.value}. Tengo ${age} años.`)
      setDia("")
      setMes("")
      setAnioNac("")
    }
    //handleSendMessage(`Soy del ${dia} de ${mes} de ${e.target.value}. Tengo ${age} años.`)


  }

  const checkMessagesAndSendConversion = (messages) => {
    if (messages.length >= 2) {
      const lastMessage = messages[messages.length - 1];
      const secondLastMessage = messages[messages.length - 2];
      if (
        lastMessage.role === 'assistant' &&
        secondLastMessage.role === 'system' &&
        lastMessage.content.length !== secondLastMessage.content.length
      ) {
        console.log("Conversion sent")
        window.gtag('event', 'conversion', {
          send_to: 'AW-16766888739/vut3COnDheYZEKPWibs-',
        });
      }
    }
  };

  const callApi = async (conversation) => {
    let chatData = collectedData
    //Manejar esto cuando es router
    if (Object.keys(collectedData).length === 0) {
      const defaultCollectedData = {
        collected_data: {
          current_car: {
            car_brand: marca,
            car_sub_brand: submarca,
            year: anio
          },
          driver_info: {
            zip_code: null,
            driver_gender: genero,
            driver_age: null,
            insurance_type: tipo
          },
          cars: []
        }
      };
      setCollectedData(defaultCollectedData);
      chatData = defaultCollectedData
    }
    const payload = { messages: conversation, convId: conversationId, convIdSimple: conversationIdSimple ? conversationIdSimple : 0, gclid: gclid, ...chatData };
  
    try {
      const data = await postDataToBackend('/chat', payload);
  
      if (data.status === 'success') {
        setMessages(data.messages);
        checkMessagesAndSendConversion(data.messages)
        setCollectedData(data.collected_data || {});
        setConversationIdSimple(data.conv_id_simple || 0)
      } else {
        console.error('API response error:', data);
      }
    } catch (error) {
      console.error('Error calling API:', error);
    }
  };

  const handleInputChange = (e) => {
    if (e.target.value.length <= 300) {
      setInput(e.target.value);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  function renderLinks(text) {
    // Regex to match both [Link Text](URL) and [Link Text]{URL} formats
    const linkRegex = /\[([^\]]+)\]\(([^)]+)\)|\[([^\]]+)\]\{([^}]+)\}/g;
  
    const payload = { tag: "WA", convId: conversationId }; 
  
    const lines = text.split('\n').map((line, index) => {
      const parts = [];
      let lastIndex = 0;
  
      line.replace(linkRegex, (match, textContent, linkParenthesis, linkCurlyText, linkCurly, offset) => {
        // Determine the link text and URL based on which format matched
        const linkText = textContent || linkCurlyText;
        const link = linkParenthesis || linkCurly;
  
        // Add the text before the link
        if (offset > lastIndex) {
          parts.push(line.slice(lastIndex, offset));
        }
  
        // Add the link with conditional onClick for WhatsApp
        parts.push(
          <a
            key={`${index}-${offset}`}
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: 'blue', textDecoration: 'underline' }}
            onClick={() => {
              if (link.includes('whatsapp.com')) {
                // Call the API without preventing the default action
                postDataToBackend('/tag', payload);
              }
            }}
          >
            {linkText}
          </a>
        );
        lastIndex = offset + match.length;
      });
  
      // Add any remaining text after the last link
      if (lastIndex < line.length) {
        parts.push(line.slice(lastIndex));
      }
  
      return (
        <span key={index}>
          {parts}
          <br /> {/* Add a line break */}
        </span>
      );
    });
  
    return lines;
  }
  

  useEffect(() => {
    if ((loaded1 && loaded2) || isTypingVisible) {
      bottomRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      });
    }
  }, [messages, loaded1, loaded2, isTypingVisible, renderGenero, renderTipo, renderFecha, shouldShowIndicator]);

  useEffect(() => {
    let showTimeout;
    let hideTimeout;

    const startCycle = () => {
      setShouldShowIndicator(true);
      
      hideTimeout = setTimeout(() => {
        setShouldShowIndicator(false);
        showTimeout = setTimeout(() => {
          if (isTypingVisible) {
            startCycle();
          }
        }, 2000);
      }, 8000);
    };

    if (isTypingVisible) {
      startCycle();
    } else {
      setShouldShowIndicator(false);
    }

    return () => {
      if (showTimeout) clearTimeout(showTimeout);
      if (hideTimeout) clearTimeout(hideTimeout);
    };
  }, [isTypingVisible]);

  return (
    loaded1 && loaded2 ? (
      <div className="flex flex-col h-screen items-center justify-top bg-transparent">
        <div className="w-full max-w-md bg-white shadow-xl rounded-xl overflow-hidden flex flex-col" style={{ height: `calc(${messages.length <= 1 ? '70vh' : '95vh'})` }}>
          <div style={{ backgroundColor: '#4790CF' }} className="text-white p-4 text-lg font-semibold flex items-center space-x-3">
            <img
              src={msol}
              alt="Profile"
              className="h-11 w-11 mr-4 rounded-full border-0"
            />
            <div className="leading-tight">
              <span className="font-bold">{agentName}</span>
              <br />
              <span className="font-light">{agentTitle}</span>
            </div>
          </div>
          <div className="flex-1 p-4 overflow-y-auto bg-[#F9F9FB]" ref={scrollContainerRef} style={{ maxHeight: '80vh' }}>
            <div className="flex flex-col space-y-4">
              {messages.length === 0 && (
                <div className="text-gray-400 text-center">
                  ¡Hola! ¿En qué puedo ayudarte?
                </div>
              )}
              {messages
                .filter((message) => message.role !== 'system')
                .map((message, index) => (
                  <div
                    key={index}
                    className={`p-3 max-w-xs break-words ${
                      message.role === 'user'
                        ? 'text-white self-end rounded-xl rounded-br-none' 
                        : 'bg-white border border-gray-200 text-black self-start rounded-xl rounded-bl-none'
                    }`}
                    style={message.role === 'user' ? { backgroundColor: '#007AFF' } : {}}
                  >
                    {message.role === 'assistant'
                      ? renderLinks(message.content) // Usa renderLinks para mensajes del asistente
                      : message.content.split('\n').map((line, idx) => (
                          <span key={idx}>
                            {line}
                            <br />
                          </span>
                        ))
                    }
                  </div>
                ))}
              {isTypingVisible && shouldShowIndicator && (
                <div className="bg-white border border-gray-200 text-black p-3 rounded-lg rounded-bl-none max-w-xs self-start flex items-center space-x-1 mt-2">
                  <span className="bg-gray-400 rounded-full w-2.5 h-2.5 animate-bounce"></span>
                  <span className="bg-gray-400 rounded-full w-2.5 h-2.5 animate-bounce animation-delay-200"></span>
                  <span className="bg-gray-400 rounded-full w-2.5 h-2.5 animate-bounce animation-delay-400"></span>
                </div>
              )}
              <div ref={bottomRef} />
            </div>
          </div>
          <div>
            {/* Dropdowns Section */}
            {messages.length === 1 && (
              <div className="p-4 flex flex-row items-end justify-end space-x-2 bg-gray-50">
                {/* Marca Dropdown */}
                <select
                  className="p-1 w-auto max-w-full truncate text-sm border border-[#007AFF] font-bold rounded-lg text-[#007AFF]"
                  style={{ height: "35px", backgroundColor: "white" }}
                  value={marca}
                  onChange={handleMarcaChange}
                >
                  <option value="" disabled>
                    Marca
                  </option>
                  {Object.keys(carData).map((marca) => (
                    <option key={marca} value={marca}>
                      {marca}
                    </option>
                  ))}
                </select>
          
                {/* Año Dropdown */}
                <select
                  className="p-1 w-auto text-sm border border-[#007AFF] font-bold rounded-lg text-[#007AFF]"
                  style={{  height: "35px", backgroundColor: "white" }}
                  value={anio}
                  onChange={handleAnioChange}
                  disabled={!marca}
                >
                  <option value="" disabled>
                    Año
                  </option>
                  {marca &&
                    Object.keys(carData[marca])
                      .sort((a, b) => b - a) // Reverse order for years
                      .map((anio) => (
                        <option key={anio} value={anio}>
                          {anio}
                        </option>
                      ))}
                </select>
          
                {/* Submarca Dropdown */}
                <select
                  className="p-1 w-auto text-sm border border-[#007AFF] font-bold rounded-lg text-[#007AFF]"
                  style={{  height: "35px", backgroundColor: "white" }}
                  value={submarca}
                  onChange={handleSubmarcaChange}
                  disabled={!anio}
                >
                  <option value="" disabled>
                    Submarca
                  </option>
                  {marca &&
                    anio &&
                    carData[marca][anio].map((submarca) => (
                      <option key={submarca} value={submarca}>
                        {submarca}
                      </option>
                    ))}
                </select>
              </div>
            )}

            {renderTipo && (
              <div className="p-4 flex flex-row items-end justify-end space-x-2 bg-gray-50">
                {/* Masculino Button */}
                <button
                  className="p-1 w-auto text-sm border border-[#007AFF] font-bold rounded-lg text-[#007AFF]"
                  style={{ height: "35px", backgroundColor: "white" }}
                  onClick={() => handleTipoSelected("Particular")}
                >
                  Particular
                </button>

                {/* Femenino Button */}
                <button
                  className="p-1 w-auto text-sm border border-[#007AFF] font-bold rounded-lg text-[#007AFF]"
                  style={{ height: "35px", backgroundColor: "white" }}
                  onClick={() => handleTipoSelected("Uber")}
                >
                  Uber
                </button>
                <button
                  className="p-1 w-auto text-sm border border-[#007AFF] font-bold rounded-lg text-[#007AFF]"
                  style={{ height: "35px", backgroundColor: "white" }}
                  onClick={() => handleTipoSelected("Plataforma")}
                >
                  Plataforma
                </button>
              </div>
            )}  

            {renderGenero && (
              <div className="p-4 flex flex-row items-end justify-end space-x-2 bg-gray-50">
                {/* Masculino Button */}
                <button
                  className="p-1 w-auto text-sm border border-[#007AFF] font-bold rounded-lg text-[#007AFF]"
                  style={{ height: "35px", backgroundColor: "white" }}
                  onClick={() => handleGeneroSelected("Masculino")}
                >
                  Masculino
                </button>

                {/* Femenino Button */}
                <button
                  className="p-1 w-auto text-sm border border-[#007AFF] font-bold rounded-lg text-[#007AFF]"
                  style={{ height: "35px", backgroundColor: "white" }}
                  onClick={() => handleGeneroSelected("Femenino")}
                >
                  Femenino
                </button>
              </div>
            )}

            {/* Fecha de Nacimiento */}
            {renderFecha && (
              <div className="p-4 flex flex-row items-end justify-end space-x-2 bg-gray-50">
                {/* Marca Dropdown */}
                <p className='p-1 text-sm font-bold text-[#007AFF]'>
                  Fecha de Nacimiento:
                </p>
                <select
                  className="p-1 w-auto max-w-full truncate text-sm border border-[#007AFF] font-bold rounded-lg text-[#007AFF]"
                  style={{ height: "35px", backgroundColor: "white" }}
                  value={dia}
                  onChange={handleDiaChange}
                >
                  <option value="" disabled>
                    Dia
                  </option>
                  {dias.map( (valor) => (
                    <option key={valor} value={valor}>
                      {valor}
                    </option>
                  ))}
                </select>
          
                {/* Año Dropdown */}
                <select
                  className="p-1 w-auto text-sm border border-[#007AFF] font-bold rounded-lg text-[#007AFF]"
                  style={{  height: "35px", backgroundColor: "white" }}
                  value={mes}
                  onChange={handleMesChange}
                  disabled={!dia}
                >
                  <option value="" disabled>
                    Mes
                  </option>
                  {meses.map( (valor) => (
                    <option key={valor} value={valor}>
                      {valor}
                    </option>
                  ))}
                </select>
          
                {/* Submarca Dropdown */}
                <select
                  className="p-1 w-auto text-sm border border-[#007AFF] font-bold rounded-lg text-[#007AFF]"
                  style={{ height: "35px", backgroundColor: "white" }}
                  value={anioNac}
                  onChange={handleAnioNacChange}
                  disabled={!mes}
                >
                  <option value="" disabled>
                    Año
                  </option>
                  {anios
                    .slice()            // Make a copy of the array to avoid mutating the original
                    .reverse()          // Reverse the copied array
                    .map((valor) => (
                      <option key={valor} value={valor}>
                        {valor}
                      </option>
                    ))}
                </select>
              </div>
            )}      

            {/* Typing Section */}
            <div className="bg-gray-200 p-4 flex items-end">
              <input
                type="text"
                className="flex-1 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:border-blue-500"
                value={input}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                onFocus={async () => {
                  if (isFocusLogged) return;
                  const payload = { tag: "focus", convId: conversationId };
                  try {
                    await postDataToBackend('/tag', payload);
                    setIsFocusLogged(true);
                  } catch (error) {
                    console.error('Error sending focus payload:', error);
                  }
                }}
                placeholder="Escribe un mensaje..."
              />
              <button
                onClick={handleSendMessage}
                className="ml-4 p-2 rounded-full hover:bg-blue-500 focus:outline-none"
                style={{ backgroundColor: '#4790CF', width: '40px', height: '40px' }}
              >
                <img src={sendSVG} alt="Send" className="w-full h-full" />
              </button>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <LoadingAnimation />
    )
  );
}

export default App;
